<mat-toolbar class="custom-header" [style.background-color]="Header.headerBackgroundColor" [style.height]="Header.headerHeight" >
  <a href="https://www.stewartdisabilitylaw.com/" id="site-link" >
    <img class="law-logo" src="assets/img/headerImg/stewart-disability-law.bmp" [style.height]="Header.logoHeight" [style.width]="Header.logoWidth" alt="test"/>
  </a>
  <!--<span class="header-title">TEST HEADER</span>-->
  <span class="example-spacer"></span>
  <div class="phone-span">
    <div class="phone-icon-bg">
      <mat-icon class="phone-icon">phone_iphone</mat-icon>
    </div>
    <a class="phone-number-link" [href]="altPhoneNumber"><span [style.color]="Header.headerTextColor">{{Header.phoneNumber}}</span></a>
  </div>
</mat-toolbar>
