import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stewart-disability-law-header',
  templateUrl: './stewart-disability-law-header.component.html',
  styleUrls: ['./stewart-disability-law-header.component.scss']
})
export class StewartDisabilityLawHeaderComponent implements OnInit {

  @Input()Header:any;
  public lawName: string;
  public altPhoneNumber:string='';
  static key ='TestHeaderComponent';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.lawName = this.route.snapshot.params['lawName'];
    this.altPhoneNumber  = this.Header.phoneNumber.replace('(','');
    this.altPhoneNumber = this.altPhoneNumber.replace(')','');
    this.altPhoneNumber = this.altPhoneNumber.replace('-','');
    this.altPhoneNumber = 'tel:+1'+this.altPhoneNumber;
  }

}
