import { Component, OnInit, HostListener, HostBinding, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Location } from '@angular/common';
import{PersonalInjuryForm} from  './interfaces/personal-injury-form/PersonalInjuryForm';
import{SocialSecurityForm} from './interfaces/social-security-form/SocialSecurityForm';
import{VeteranAffairsForm} from './interfaces/veteran-affairs-form/VeteranAffairsForm';
import{WorkersCompForm} from './interfaces/workers-comp-form/WorkersCompForm';
import { salutations } from '../../common/salutations';
import {maritalStatuses} from '../../common/maritalStatuses';
import { sex } from '../../common/sex';
import { state as stateList } from '../../common/states';
import { booleanValues } from '../../common/boolean';
import { caseTypes as cTypes } from '../../common/caseTypes';
import {FormTypes} from  '../../common/enums/formTypes.enum';
import {FormSections} from '../../common/enums/formSections.enum';
import {FormService} from  './services/forms.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {headerComponentMap} from './headers/headerComponentMap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgressSnackBarComponent } from 'src/app/shared/progress-snack-bar/progress-snack-bar.component';
import { MatPaginator } from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { subscribeOn } from 'rxjs/operators';
import { FormCompleteModalComponent } from '../modals/form-complete-modal/form-complete-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SignatureModalComponent } from '../modals/signature-modal/signature-modal.component';
@Component({
  selector: 'forms-component',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '250px' })),
      state('false', style({ width: '50px' })),
      transition('false <=> true', animate('400ms ease-in')),
    ]),
  ],
})
export class FormsComponent implements AfterViewInit, OnInit {
  public columnSize: number = 4;
  public groupRowSize:number = 4;

  @HostBinding ("style.--company-color")
  public get companyColorAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.companyColor);
  }
  @HostBinding ("style.--secondary-company-color")
  public get secondaryCompanyColorAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryCompanyColor);
  }
  @HostBinding("style.--company-Label")
  public get labelAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.labelColor);
  }

  @HostBinding("style.--secondary-company-Label")
  public get secondarylabelAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.secondaryLabelColor);
  }

  @HostBinding("style.--snackbar-bg")
  public get snackbarBgAsStyle():any{
    return this.sanitizer.bypassSecurityTrustStyle(this.snackBarBg);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (event) {
      if (event.target.innerWidth > 1150) {
        this.columnSize = 4;
        this.groupRowSize=4;
      } else if (
        event.target.innerWidth <= 1150 &&
        event.target.innerWidth > 880
      ) {
        this.columnSize = 3;
        this.groupRowSize=4;
      } else if (
        event.target.innerWidth <= 880 &&
        event.target.innerWidth > 700
      ) {
        this.columnSize = 2;
        this.groupRowSize = 8;
      } else if (event.target.innerWidth <= 700) {
        if (this.columnSize > 1) {
          this.isOpen = false;
        }
        this.columnSize = 1;
        this.groupRowSize=8;
      }
    } else {
      if (window.innerWidth > 1150) {
        this.columnSize = 4;
        this.groupRowSize = 4;
      } else if (window.innerWidth <= 1150 && window.innerWidth > 880) {
        this.columnSize = 3;
        this.groupRowSize=4;
      } else if (window.innerWidth <= 880 && window.innerWidth > 700) {
        this.columnSize = 2;
        this.groupRowSize=8;
      } else if (window.innerWidth <= 700) {
        if (this.columnSize > 1) {
          this.isOpen = false;
        }
        this.columnSize = 1;
        this.groupRowSize=8;
      }
    }
  }
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('customHeader',{read:ViewContainerRef})entry:ViewContainerRef;
  @ViewChild('multiPaginator') set paginator(pager: MatPaginator){
    if (pager) this.dataSource.paginator = pager;
  }

  public lawName: string;
  public isOpen: boolean;
  public cbIsShown: boolean = false;
  public cbDisabled: boolean = false;
  public salutes = salutations;
  public sexes = sex;
  public states = stateList;
  public maritalStatusList = maritalStatuses;
  public bValues = booleanValues;
  public caseTypes = cTypes;
  public pickerName = 'picker1';
  public pickerId = '#picker1';
  public selectedForm = 'Personal Injury';
  public formSubmitted = false;
  public isTvcDischargeRoute: boolean = false;
  public showTvcNoUploadMessage: boolean = false;
  private header:any={};
  private companyColor = '';
  public secondaryCompanyColor='';
  private labelColor = '';
  private secondaryLabelColor='';
  private clickCount:number = 0;
  private snackBarBg:string = '';
  private currentRouter = this.router.url;
  public selectedFiles:any;
  public isLoading:boolean = true;
  public isFormUploading:boolean =false;
  public dataSource= new MatTableDataSource<any>([]);
  public displayedColumns: string[] = ['delete','name', 'weight', 'symbol'];

  public pdfSrc = "assets/pdf/firstTest.pdf";
 // private header= 'TestHeaderComponent';
  public forms:any[];
  public groupControlObj={
    name:'',
    weight:0,
    symbol:''
  };

  private formId: number = 0;

  constructor(private router:Router, private location: Location, private route: ActivatedRoute, public formService:FormService, private formBuilder:FormBuilder, private sanitizer:DomSanitizer, private resolver:ComponentFactoryResolver
    ,private _snackBar: MatSnackBar,private cdr: ChangeDetectorRef,
    public dialog: MatDialog) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.lawName = this.route.snapshot.params['lawName'];

    if (this.lawName === 'tvcdischargeupgrade') {
      this.isTvcDischargeRoute = true;
      this.showTvcNoUploadMessage = this.isTvcHolidaySeason();
    }

    this.onResize();



    this.formService.getForms(this.lawName).subscribe(
      (data)=>{
        this.isLoading=false;
        this.forms = data["forms"];
        this.companyColor = data["companyColor"];
        this.secondaryCompanyColor = data["secondaryColor"];
        this.labelColor = data["companyLabelColor"];
        this.secondaryLabelColor = data["secondaryLabelColor"];
        this.header = data["companyHeader"];
        this.formService.selectedForm = this.forms[0].formName;
        this.formId = this.forms[0].id;
        this.formService.selectedFormTypeId = this.forms[0].formTypeId;


        this.createHeaderComponent();
        //Configure Form Models
        this.formService.createFormModels(this.forms);
      },
      (error)=>{
        console.log(error);
      }
    );
  }

  private isTvcHolidaySeason(): boolean {
    const today: Date = new Date();


    const startDate: Date = new Date(2024, 11, 20);
    const endDate: Date = new Date(2025, 0, 5);

    return today >= startDate && today <= endDate;
  }

  public openSnackBar(message: string, action: string) {
    if(this.clickCount == 0){
      this.formService.setValue('loading');
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 25000 ,
        panelClass:['snackbar-loading']
      });
      this.snackBarBg ='#363636';
    }
    else if(this.clickCount == 1){
      this.showSuccessSnackBar();
    }
    else if(this.clickCount == 2){
      this.showErrorSnackBar();
    }
    else if(this.clickCount == 3){
      this.formService.setValue('loading');
      this._snackBar.dismiss();
    }

    if(this.clickCount == 3){
      this.clickCount = 0;
    }
    else{
      this.clickCount++;
    }
  }

  private showLoadingSnackBar(){
    this.formService.setValue('loading');
    this._snackBar.openFromComponent(ProgressSnackBarComponent,{
      duration:0,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass:['snackbar-loading']
    });
    this.snackBarBg ='#363636';
  }

  private showSuccessSnackBar(){
    this._snackBar.dismiss();
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-success']
      });
      this.formService.setValue('success');
      this.snackBarBg = '#4bb543';
  }

  private showFormValidationErrorSnackBar() {
    this._snackBar.dismiss();

    this.formService.setValue('form-validation-error');
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-error']
      });
      this.snackBarBg = '#ff9494';
      window.scroll(0,0);
  }

  private showErrorSnackBar() {
    let otherInfo: string = 'Try removing attached files. Upload size may be too large.';
    this._snackBar.dismiss();
      this.formService.setValue('error', otherInfo);
      this._snackBar.openFromComponent(ProgressSnackBarComponent,{
        duration:5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass:['snackbar-error']
      });
      this.snackBarBg = '#ff9494';
  }

  private createHeaderComponent(){
    this.entry.clear();

    const factory = this.resolver.resolveComponentFactory(headerComponentMap[this.header?.headerComponent]);

    var c = this.entry.createComponent(factory);
    c.instance["Header"] = this.header;
  }
  public updateCb(val) {
    this.cbDisabled = val;
  }

  selectFile(event,form,section, c) {
    this.selectedFiles = (event.target as HTMLInputElement).files;
    let f = this.selectedFiles[0];

    let reader = new FileReader();
    reader.readAsDataURL(this.selectedFiles[0]);
    reader.onload = () => {
      this.formService.formGroups[form.groupName].controls[section.groupName].controls[c.name].value = {base64String:reader.result, name:this.selectedFiles[0].name, type:this.selectedFiles[0].type};
    }
  }

  public testGroupAdd(){

    var val = this.formService.formGroups['PersonalInjury'].controls['personalInformation'].controls['medicalProviders'].value;
    val['delete'] = this.createGuid();

    this.dataSource.data.push(
      val
    );
    this.formService.formGroups['PersonalInjury'].controls['personalInformation'].controls['medicalProviders'].reset();

    var d = this.dataSource.data;
    this.dataSource.data = [...d];
  }

  public deleteRow(id){
    var d = [];
    this.dataSource.data.forEach((r) => {
      if(r.delete != id){
        d.push(r);
      }
    });

    this.dataSource.data = [...d];
  }

  private createGuid(){
    return this.guidGenerator(false) +
    this.guidGenerator(true) +
    this.guidGenerator(true)+
    this.guidGenerator(false);
  }

  private guidGenerator(s){
    var p = (Math.random().toString(16)+"000000000").substr(2,8);
    return s ? "-" + p.substr(0,4)+"-" + p.substr(4,4):p;
  }

  public onSubmit(groupName:string){
    this.formService.submittedForms[groupName] = true;
    if (this.formService.isFormGroupControlsValid(groupName) && this.formService.formGroups[groupName].valid) {
      this.showLoadingSnackBar();
      this.isFormUploading = true;
      if(this.formService.selectedFormTypeId === FormTypes.CUSTOM){
        this.formService.createCustomFormObj(groupName);
        this.formService.submitCustomForm(this.formService.createCustomFormObj(groupName),this.lawName, this.formId).subscribe(
          (data)=>{
            this.clearForm(groupName);
            this.isFormUploading = false;
            let messageVal = this.forms.find(f => f.name === this.formService.selectedForm).formCompleteMessage;
            if(messageVal && this.lawName === 'stewartdisabilitylaw') {
              this.openFormCompleteDialog(messageVal);
            }
            else {
              this.reloadComponent();
            }
          },
          (error) =>{
            console.log('Error');
            this.formService.submitError(JSON.stringify(error), this.lawName).subscribe(res => {
              this.isFormUploading = false;
            },
            err => {
              console.log(err);
              this.isFormUploading = false;
            });
            this.showErrorSnackBar();
            this.isFormUploading = false;
          }
        );
      }
      else if(groupName === 'PersonalInjury'){
        this.formService.submitPersonalInjuryForm(this.formService.formGroups[groupName].value,this.lawName).subscribe(
          (data)=>{
            this.clearForm(groupName);
            this.isFormUploading = false;
            this.reloadComponent();
          },
          (error) =>{
            console.log('Error');
            this.showErrorSnackBar();
            this.isFormUploading = false;
          }
        );
      }
      else if(groupName === 'SocialSecurity'){
        this.formService.submitSocialSecurityForm(this.formService.formGroups[groupName].value,this.lawName).subscribe(
          (data)=>{
            this.clearForm(groupName);
            this.isFormUploading = false;
            this.reloadComponent();
          },
          (error) =>{
            console.log('Error');
            this.showErrorSnackBar();
            this.isFormUploading = false;
          }
        );
      }
      else if(groupName === 'VeteranAffairs'){
        this.formService.submitVeteranAffairsForm(this.formService.formGroups[groupName].value,this.lawName).subscribe(
          (data)=>{
            this.clearForm(groupName);
            this.isFormUploading = false;
            this.reloadComponent();
          },
          (error) =>{
            console.log('Error');
            this.showErrorSnackBar();
            this.isFormUploading = false;
          }
        );
      }
      else if(groupName === 'WorkersCompensation'){
        this.formService.submitWorkersCompensationForm(this.formService.formGroups[groupName].value,this.lawName).subscribe(
          (data)=>{
            this.clearForm(groupName);
            this.isFormUploading = false;
            this.reloadComponent();
          },
          (error) =>{
            console.log('Error');
            this.showErrorSnackBar();
            this.isFormUploading = false;
          }
        );
      }
      else if(groupName === 'Medicare'){
        this.formService.submitMedicareForm(this.formService.formGroups[groupName].value,this.lawName).subscribe(
          (data)=>{
            this.clearForm(groupName);
            this.isFormUploading = false;
            this.reloadComponent();
          },
          (error) =>{
            this.showErrorSnackBar();
            this.isFormUploading = false;
          }
        );
      }
      else{
        //Custom Form
        //Create Custom FormObj
      }
    }
    else{
      this.showFormValidationErrorSnackBar();
      this.formService.setCustomControlUpdate(true);
      console.log('Form is Invalid');
    }
  }

  openSignatureDialog(val:string): void {
    const dialogRef = this.dialog.open(SignatureModalComponent, {
      width: '600px',
      data: val,
      panelClass: 'form-complete-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadComponent();
    });
  }

  openFormCompleteDialog(val:string): void {
    const dialogRef = this.dialog.open(FormCompleteModalComponent, {
      width: '600px',
      data: val,
      panelClass: 'form-complete-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reloadComponent();
    });
  }
  private reloadComponent(){
    this.router.navigate([this.currentRouter]);
  }

  private clearForm(groupName:string){
    this.formService.submittedForms[groupName] = false;
    this.formService.formGroups[groupName].reset();
    this.formService.formGroups[groupName].clearValidators();
    this.formService.formGroups[groupName].markAsPristine();
    this.formService.formGroups[groupName].markAsUntouched();
    this.formService.formGroups[groupName].updateValueAndValidity()
    Object.keys(this.formService.formGroups[groupName].controls).forEach(key => {
      this.formService.formGroups[groupName].controls[key].clearValidators();
      this.formService.formGroups[groupName].controls[key].markAsPristine();
      this.formService.formGroups[groupName].controls[key].markAsUntouched();
      this.formService.formGroups[groupName].controls[key].updateValueAndValidity();
      Object.keys(this.formService.formGroups[groupName].controls[key].controls).forEach(newKey =>{
        this.formService.formGroups[groupName].controls[key].controls[newKey].clearValidators();
        this.formService.formGroups[groupName].controls[key].controls[newKey].markAsPristine();
        this.formService.formGroups[groupName].controls[key].controls[newKey].markAsUntouched();
        this.formService.formGroups[groupName].controls[key].controls[newKey].updateValueAndValidity();
      });
    });

    this.formService.groupValuesClearList.forEach(k => {
      this.formService.groupValues.set(k, null);
    });

    this.formService.groupValuesClearList = [];
    this.formSubmitted = false;
    this.showSuccessSnackBar();
  }
  public goBack(){
    if (window.history.length > 1) {
      this.location.back()
    } else {
      this.router.navigate(['/home'])
    }
  }

  public selectSideMenu(formName:string,formTypeId:number,item) {
    this.formId = item.id;
    this.formService.selectedForm = formName;
    this.formService.selectedFormTypeId = formTypeId;
    this.formService.setCustomControlUpdate(false);
  }

  public showHide(control){

    let retVal: boolean  = false;

    if(control?.showHideControls?.length > 0){
      control.showHideControls.forEach(shCtrl => {
        if(this.formService.formGroups[shCtrl.formName].controls[shCtrl.sectionName].controls[shCtrl.connectedControlName].value === shCtrl.showHideValue){
            retVal = true;
        }
        else {
          if(control.type !== 'multiGroup')
            this.formService.formGroups[shCtrl.formName].controls[shCtrl.sectionName].controls[shCtrl.controlName].setValue('');
        }
      });
    }
    else{
      retVal = true;
    }

    return retVal;

  }
}
